var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: { title: _vm.title, width: "500px", color: "error" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "next",
                  attrs: {
                    width: "30vw",
                    height: "40px",
                    "max-width": "170px",
                    dark: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.goHome()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("button.goHome")) + "\n    "
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-container", [
        _c(
          "div",
          { staticClass: "text-center", staticStyle: { margin: "0 auto" } },
          [
            _vm.message
              ? _c(
                  "v-card-text",
                  { staticClass: "text-center py-0 py-3 mx-auto" },
                  _vm._l(_vm.splitMessageLine(_vm.message), function(
                    text,
                    index
                  ) {
                    return _c(
                      "v-col",
                      { key: index, class: _vm.isLineBreakStyle(text) },
                      [_vm._v(_vm._s(text) + "\n      ")]
                    )
                  }),
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm.errorMessageList.length > 0
        ? _c(
            "v-container",
            {
              staticClass: "overflow-y-auto mx-auto",
              staticStyle: {
                "max-height": "30vh",
                "max-width": "90%",
                border: "#ddd 3px solid"
              },
              attrs: { id: "scroll-target", wrap: "" }
            },
            _vm._l(_vm.errorItemList, function(text, index) {
              return _c(
                "v-row",
                { key: index, staticClass: "my-0" },
                [
                  _c("v-col", [
                    _vm._v("\n        " + _vm._s(text) + "\n      ")
                  ]),
                  _c("v-col", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.errorMessageList[index]) +
                        "\n      "
                    )
                  ])
                ],
                1
              )
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }