<template>
  <v-container fluid fill-height>
    <v-layout column fill-height wrap>
      <v-card width="50vw" class="ma-auto">
        <v-row class="my-12">
          <p
            class="font-weight-black text-center fx-20 mx-auto"
            style="font-size:32px"
          >
            {{ $t('title.agency.login') }}
          </p>
        </v-row>
        <ValidationForms ref="forms">
          <v-row style="width: 70%; max-width: 350px;" class="mx-auto mb-6">
            <ValidationForm :rules="'required'">
              <v-text-field
                v-model="id"
                solo
                type="text"
                :label="$t('label.id')"
                hide-details
              ></v-text-field>
            </ValidationForm>
          </v-row>
          <v-row style="width: 70%; max-width: 350px;" class="mx-auto">
            <ValidationForm :rules="'required'">
              <v-text-field
                v-model="password"
                solo
                type="password"
                :label="$t('label.password')"
                hide-details
              ></v-text-field>
            </ValidationForm>
          </v-row>
          <template v-if="isError">
            <p class="font-weight-bold text-center" style="color:red">
              {{ errorMessage }}
            </p>
          </template>
        </ValidationForms>
        <v-row class="mt-12 mb-​​6">
          <v-btn
            class="font-weight-black mx-auto"
            align="center"
            width="70%"
            max-width="350px"
            color="next"
            style="font-size:20px"
            dark
            @click="onLoginClick"
            >{{ $t('label.login') }}</v-btn
          >
        </v-row>
        <v-row class="my-6">
          <p class="text-center fx-20 mx-auto" style="font-size:15px">
            {{ $t('description.loginTop.explanation1') }}
          </p>
        </v-row>
        <v-btn text @click="onClickForgotPassword" class="float-right pa-0">
          <p
            class="v_search_box_toggle  mb-0"
            style="text-decoration: underline;"
          >
            {{ $t('button.forgotPassword') }}
          </p>
        </v-btn>
      </v-card>
      <PasswordChangeDialog
        @onSuccess="onSuccessPasswordChange"
        @onComplete="onCompletePasswordChange"
        ref="passwordChangeDialog"
      />
      <!-- パスワード初期化申請ダイアログ -->
      <FormsDialog
        :showDialog="showForgotPasswordDialog"
        :title="$t('title.agency.forgotPassword')"
        :text="$t('description.forgotPassword')"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.next')"
        :onClickNegativeButton="cancelForgotPassword"
        :onClickPositiveButton="getSecretQuestion"
        :targets="forgotPasswordTargets"
      />
      <!-- 秘密の質問入力ダイアログ -->
      <FormsDialog
        :showDialog="showSecretQuestionDialog"
        :title="$t('title.agency.secretQuestion')"
        :text="secretQuestionText"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.send')"
        :onClickNegativeButton="cancelSecretQuestions"
        :onClickPositiveButton="onForgotPassword"
        :targets="secretQuestionTargets"
      />
      <ErrorDialog ref="errorDialog"></ErrorDialog>
      <CompletedDialog ref="completedDialog"></CompletedDialog>
      <!-- SJNET連携エラーダイアログ -->
      <SimpleDialog
        :maxWidth="500"
        :showDialog="showSjNetErrorDialog"
        :title="sjNetErrorTitle"
        :confirmText="sjNetErrorText"
        :negativeButtonTitle="sjNetErrorNegativeButtonTitle"
        :positiveButtonTitle="sjNetErrorPositiveButtonTitle"
        :onClickNegativeButton="sjNetErrorNegativeButton"
        :onClickPositiveButton="sjNetErrorPositiveButton"
      />
      <!-- SJNET連携バリデーションエラーダイアログ -->
      <CaseDataValidateErrorDialog
        ref="caseDataValidateErrorDialog"
      ></CaseDataValidateErrorDialog>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import SimpleDialog from '@/components/organisms/agency/SimpleDialog';
import CaseDataValidateErrorDialog from '@/components/organisms/agency/CaseDataValidateErrorDialog';
import ValidationForms from '@/components/organisms/common/ValidationForms';
import ValidationForm from '@/components/organisms/common/ValidationForm';
import PasswordChangeDialog from '@/components/organisms/agency/PasswordChangeDialog';
import {
  MaxCount,
  UserStatus,
  SecretQuestions,
  SjNetError,
} from '@/lib/const';
import { putLoginUser, postSecretQuestion } from '@/apis/agency/users';
import {
  forgotPassword,
  getSecretQuestion,
} from '@/apis/agency/noncertifications';
import { importExternalContractData } from '@/apis/agency/externals';

export default {
  name: 'Login',
  components: {
    CompletedDialog,
    ErrorDialog,
    FormsDialog,
    ValidationForms,
    ValidationForm,
    PasswordChangeDialog,
    SimpleDialog,
    CaseDataValidateErrorDialog,
  },

  data() {
    return {
      id: null,
      password: null,
      isError: false,
      errorMessage: '',

      // ユーザーID
      userId: null,

      showForgotPasswordDialog: false,
      forgotPasswordDefaultTargets: [
        {
          value: null,
          targetName: 'loginId',
          type: 'text',
          label: this.$t('label.id'),
          text: null,
          rule: 'required',
          key: 'loginId',
        },
      ],
      forgotPasswordTargets: [],
      forgotUserId: null,

      showSecretQuestionDialog: false,
      secretQuestionText: null,
      secretQuestionDefaultTargets: [
        {
          value: null,
          targetName: 'answer',
          type: 'text',
          label: this.$t('label.answer'),
          text: null,
          rule: 'required',
          key: 'answer',
        },
      ],
      secretQuestionTargets: [],

      externalCode: null,

      // SJNET連携エラーダイアログ
      showSjNetErrorDialog: false,
      sjNetErrorTitle: null,
      sjNetErrorText: null,
      sjNetErrorNegativeButtonTitle: null,
      sjNetErrorPositiveButtonTitle: null,
      sjNetErrorNegativeButton: null,
      sjNetErrorPositiveButton: null,
      sjNetConnectCount: 0,
    };
  },
  methods: {
    ...mapActions('user', ['getLoginUser']),
    ...mapActions('ui', ['setLoading']),

    // 最終ログイン日時の更新
    async updateLastLoginDate() {
      // ユーザーの更新
      await putLoginUser({
        lastLoginDate: new Date(),
      }).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.userUpdateError'),
          this.$t('error.userUpdateBadRequest')
        );
      });
    },

    async onLoginClick() {
      if (await this.$refs.forms.validate()) {
        const loginResult = await this.$cognito
          .login(this.id, this.password)
          .catch(() => {
            this.$set(this, 'isError', true);
            this.$set(this, 'errorMessage', this.$t('error.loginCheckError'));
          });
        if (loginResult) {
          // ログインユーザー情報の取得
          const user = await this.getLoginUser().catch(() => {});

          // ログインユーザーが取得できなかった場合、以降の処理を中止する
          if (!user) return;

          // ユーザーIDをセットする
          this.$set(this, 'userId', user.id);

          // 最終ログイン時刻の更新
          await this.updateLastLoginDate();
          // ユーザーのステータスが有効でない場合
          if (user.status !== UserStatus.Activated) {
            this.$refs.passwordChangeDialog.init(this.password);
            this.$refs.passwordChangeDialog.open();
          } else {
            if (this.externalCode) {
              this.onExternalDataImport();
            } else {
              this.$router.push('/Home');
            }
          }
        }
      }
    },
    // パスワード変更の成功時
    async onSuccessPasswordChange(condition) {
      // Cognito パスワード変更
      const changePasswordResult = await this.$cognito
        .changePassword(condition.currentPassword, condition.newPassword)
        .catch(err => {
          this.$refs.passwordChangeDialog.onErrorUpdate(
            this.$t(`error.passwordChangeError.${err.code}`)
          );
        });
      // パスワード変更に失敗した場合、以降の処理を中止する
      if (!changePasswordResult) return;

      // 秘密の質問作成
      if (condition.secretQuestion) {
        const createSecretQuestion = await postSecretQuestion(
          condition.secretQuestion
        ).catch(() => {
          this.$refs.errorDialog.open(
            this.$t('title.agency.userUpdateError'),
            this.$t('error.secretQuestionRegisterBadRequest')
          );
        });
        // 秘密の質問作成に失敗した場合、以降の処理を中止する
        if (!createSecretQuestion) {
          // パスワードの復元
          await this.$cognito
            .changePassword(condition.newPassword, condition.currentPassword)
            .catch(err => {
              this.$refs.passwordChangeDialog.onErrorUpdate(
                this.$t(`error.passwordChangeError.${err.code}`)
              );
            });
          return;
        }
      }

      // ユーザーの更新
      const updateUserResult = await putLoginUser({
        status: UserStatus.Activated,
      }).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.userUpdateError'),
          this.$t('error.userUpdateBadRequest')
        );
      });

      // ユーザーの更新に失敗した場合、以降の処理を中止する
      if (!updateUserResult) return;

      this.$refs.passwordChangeDialog.close();
      this.$refs.passwordChangeDialog.onUpdateSuccess();
    },

    // パスワード変更の完了時
    onCompletePasswordChange() {
      this.$router.push('/Home');
    },
    onClickForgotPassword() {
      this.$set(
        this,
        'forgotPasswordTargets',
        JSON.parse(JSON.stringify(this.forgotPasswordDefaultTargets))
      );
      this.$set(this, 'showForgotPasswordDialog', true);
    },
    cancelForgotPassword() {
      this.$set(this, 'showForgotPasswordDialog', false);
    },
    async getSecretQuestion(item) {
      this.$set(this, 'forgotUserId', item.loginId);

      const secretQuestion = await getSecretQuestion({
        loginId: this.forgotUserId,
      });

      const SecretQuestion = SecretQuestions.filter(
        question => question.value === secretQuestion.questionId
      );
      this.$set(this, 'secretQuestionText', SecretQuestion[0].text);

      this.$set(
        this,
        'secretQuestionTargets',
        JSON.parse(JSON.stringify(this.secretQuestionDefaultTargets))
      );
      this.$set(this, 'showForgotPasswordDialog', false);
      this.$set(this, 'showSecretQuestionDialog', true);
    },
    cancelSecretQuestions() {
      this.$set(this, 'showSecretQuestionDialog', false);
    },
    async onForgotPassword(item) {
      const result = await forgotPassword({
        loginId: this.forgotUserId,
        answer: item.answer,
      }).catch(err => {
        console.log(err);
      });

      if (result) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.forgotPasswordSuccess'),
          this.$t('success.forgotPassword')
        );
      } else {
        this.$refs.errorDialog.open(
          this.$t('title.agency.forgotPasswordFailure'),
          this.$t('error.forgotPassword')
        );
      }
      this.$set(this, 'showSecretQuestionDialog', false);
    },
    async onExternalDataImport() {
      this.setLoading(true);
      this.sjNetConnectCount++;
      const result = await importExternalContractData(this.externalCode).catch(
        err => {
          const error = Object
              .values(SjNetError.accessToken)
              .concat(Object.values(SjNetError.getContract))
              .find(error => error.code === err.code);

          this.$set(
            this,
            'sjNetErrorTitle',
            error ? error.title : this.$t('title.agency.sjNetError.accessToken.notConnection')
          );
          this.$set(
            this,
            'sjNetErrorText',
            error ? error.description : this.$t('error.sjNetError.accessToken.notConnection')
          );
          if (err.code === SjNetError.getContract.validateError.code) {
            const errorList = err.message.errorList;
            const errorKeyList = [];
            const translated = [];
            errorList.forEach(obj => {
              errorKeyList.push(`${this.$t(`caseData.${obj.item}`)}`);
              translated.push(
                `${this.$t(`error.caseDataImport.${obj.error}`)}`
              );
            });
            this.$refs.caseDataValidateErrorDialog.open(
              this.sjNetErrorTitle,
              this.sjNetErrorText,
              errorKeyList,
              translated
            );
          } else {
            this.$set(this, 'showSjNetErrorDialog', true);
            if ([SjNetError.accessToken.timeout.code, SjNetError.getContract.timeout.code].includes(err.code)) {
              if (this.sjNetConnectCount < MaxCount.SjNetConnectCount) {
                this.$set(
                  this,
                  'sjNetErrorPositiveButtonTitle',
                  this.$t('button.retry')
                );
                this.$set(
                  this,
                  'sjNetErrorPositiveButton',
                  this.onExternalDataImportRetry
                );
              }
              this.$set(
                this,
                'sjNetErrorNegativeButtonTitle',
                this.$t('button.sjNetNotRetry')
              );
              this.$set(this, 'sjNetErrorNegativeButton', this.goHome);
            } else {
              this.$set(
                this,
                'sjNetErrorPositiveButtonTitle',
                this.$t('button.goHome')
              );
              this.$set(this, 'sjNetErrorPositiveButton', this.goHome);
            }
          }
        }
      );

      if (result) {
        this.$router.push({
          path: `/contract_detail/${result.id}`,
        });
      }

      this.setLoading(false);
    },
    goHome() {
      this.$router.push('/Home');
    },
    onExternalDataImportRetry() {
      this.$set(this, 'showSjNetErrorDialog', false);
      this.$set(this, 'sjNetErrorPositiveButtonTitle', null);
      this.$set(this, 'sjNetErrorPositiveButton', null);
      this.$set(this, 'sjNetErrorNegativeButtonTitle', null);
      this.$set(this, 'sjNetErrorNegativeButton', null);
      this.onExternalDataImport();
    },
  },
  mounted() {
    // リロード時、ログインが成功しないようアクセストークン・リフレッシュトークンを初期化する
    this.$cognito.clearStorage();
    // NOTE: vueの仕様上、クエリパラメータの+記号は半角スペースに自動置換されるため戻し作業を実施
    if (this.$route.query.P)
      this.$set(this, 'externalCode', this.$route.query.P.replace(/ /g, '+'));
  },
};
</script>
