var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-card",
            { staticClass: "ma-auto", attrs: { width: "50vw" } },
            [
              _c("v-row", { staticClass: "my-12" }, [
                _c(
                  "p",
                  {
                    staticClass: "font-weight-black text-center fx-20 mx-auto",
                    staticStyle: { "font-size": "32px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("title.agency.login")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c(
                "ValidationForms",
                { ref: "forms" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-auto mb-6",
                      staticStyle: { width: "70%", "max-width": "350px" }
                    },
                    [
                      _c(
                        "ValidationForm",
                        { attrs: { rules: "required" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              type: "text",
                              label: _vm.$t("label.id"),
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.id,
                              callback: function($$v) {
                                _vm.id = $$v
                              },
                              expression: "id"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-auto",
                      staticStyle: { width: "70%", "max-width": "350px" }
                    },
                    [
                      _c(
                        "ValidationForm",
                        { attrs: { rules: "required" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              type: "password",
                              label: _vm.$t("label.password"),
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.isError
                    ? [
                        _c(
                          "p",
                          {
                            staticClass: "font-weight-bold text-center",
                            staticStyle: { color: "red" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.errorMessage) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "v-row",
                { staticClass: "mt-12 mb-​​6" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black mx-auto",
                      staticStyle: { "font-size": "20px" },
                      attrs: {
                        align: "center",
                        width: "70%",
                        "max-width": "350px",
                        color: "next",
                        dark: ""
                      },
                      on: { click: _vm.onLoginClick }
                    },
                    [_vm._v(_vm._s(_vm.$t("label.login")))]
                  )
                ],
                1
              ),
              _c("v-row", { staticClass: "my-6" }, [
                _c(
                  "p",
                  {
                    staticClass: "text-center fx-20 mx-auto",
                    staticStyle: { "font-size": "15px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("description.loginTop.explanation1")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "float-right pa-0",
                  attrs: { text: "" },
                  on: { click: _vm.onClickForgotPassword }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "v_search_box_toggle mb-0",
                      staticStyle: { "text-decoration": "underline" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("button.forgotPassword")) +
                          "\n        "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c("PasswordChangeDialog", {
            ref: "passwordChangeDialog",
            on: {
              onSuccess: _vm.onSuccessPasswordChange,
              onComplete: _vm.onCompletePasswordChange
            }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showForgotPasswordDialog,
              title: _vm.$t("title.agency.forgotPassword"),
              text: _vm.$t("description.forgotPassword"),
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.next"),
              onClickNegativeButton: _vm.cancelForgotPassword,
              onClickPositiveButton: _vm.getSecretQuestion,
              targets: _vm.forgotPasswordTargets
            }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showSecretQuestionDialog,
              title: _vm.$t("title.agency.secretQuestion"),
              text: _vm.secretQuestionText,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.send"),
              onClickNegativeButton: _vm.cancelSecretQuestions,
              onClickPositiveButton: _vm.onForgotPassword,
              targets: _vm.secretQuestionTargets
            }
          }),
          _c("ErrorDialog", { ref: "errorDialog" }),
          _c("CompletedDialog", { ref: "completedDialog" }),
          _c("SimpleDialog", {
            attrs: {
              maxWidth: 500,
              showDialog: _vm.showSjNetErrorDialog,
              title: _vm.sjNetErrorTitle,
              confirmText: _vm.sjNetErrorText,
              negativeButtonTitle: _vm.sjNetErrorNegativeButtonTitle,
              positiveButtonTitle: _vm.sjNetErrorPositiveButtonTitle,
              onClickNegativeButton: _vm.sjNetErrorNegativeButton,
              onClickPositiveButton: _vm.sjNetErrorPositiveButton
            }
          }),
          _c("CaseDataValidateErrorDialog", {
            ref: "caseDataValidateErrorDialog"
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }